import { graphql, useStaticQuery } from "gatsby";

import Footer from "./footer";
import Header from "./header";
import PropTypes from "prop-types";
import React from "react";
import useMeasure from "./shared/useMeasure";

const Layout = ({ children }) => {
  const [bind1, bounds] = useMeasure();
  const [bind2, bounds2] = useMeasure();
  // const [bindTop] = useMeasure()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header bind={bind1} siteTitle={data.site.siteMetadata.title} />
      <main
        style={{ height: `calc(100vh - ${bounds.height + bounds2.height}px)` }}
        className=" font-main "
      >
        {children}
      </main>
      <Footer bind={bind2} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
