import { Link, graphql, useStaticQuery } from "gatsby"

import Headroom from "react-headroom"
import Img from "gatsby-image"
import Nav from "./nav"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ bind }) => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "darpa_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <footer
        {...bind}
        className="left-0 md:text-base text-xs bottom-0 fixed z-50 bg-off text-black  flex justify-end h-10 align-center px-2  font-main text-right w-full"
      >
        <div className="text-xs self-center">
          © Darpa Records {new Date().getFullYear()}
        </div>
      </footer>
    </>
  )
}

export default Footer
