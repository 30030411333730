import { Link, graphql, useStaticQuery } from "gatsby"

import Headroom from "react-headroom"
import Img from "gatsby-image"
import Nav from "./nav"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, bind }) => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "darpa_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Headroom>
        <Nav bind={bind} />
      </Headroom>
    </>
  )
}

export default Header
