import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { animated as a, useSpring } from "react-spring"

import Img from "gatsby-image"
import Logo from "../../assets/svgs/logo1.svg"
import LogoWhite from "../../assets/svgs/logowhite.svg"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"

const routes = [
  {
    route: `/`,
    title: `LATEST`,
  },
  {
    route: `/artists`,
    title: `ARTISTS`,
  },
  {
    route: `/releases`,
    title: `DISCOGRAPHY`,
  },
  {
    route: `/about`,
    title: `ABOUT`,
  },
]
const StyledLink = styled(Link)`
  text-shadow: 0;
  &:hover {
    text-shadow: 0px 0px 1px white;
  }
  &:after {
    text-decoration: underline;
  }
`
const isBrowser = typeof window !== "undefined"


// animated react spring div
const MobNav = styled(a.nav)`
  position: relative;
  width: 100%;
  border-bottom: 1px;
  border-color: white;
  border-style: solid;
  will-change: height, opacity;
`
const M = ({ setOpen, state }) => {
  useLockBodyScroll()
  return (
    <MobNav className="flex p-4  flex-col h-full font-main ">
      {routes.map(link => (
        <>
          <StyledLink
            onClick={e => setOpen(!state)}
            className=" font-variable font-medium outline-none tracking-normal  text-2xl "
            key={link.title}
            to={link.route}
          >
            {link.title}
          </StyledLink>
        </>
      ))}
    </MobNav>
  )
}

const MobileNav = () => {
  const [open, setOpen] = useState(false)
  const { height, opacity } = useSpring({
    height: `${open ? 100 : 0}vh`,
    opacity: open ? 0 : 1,
    // config: { mass: 2, tension: 200, friction: 70 },
  })
  return (
    <>
      <div className="w-full bg-black">
        <button className="h-10  my-3" onClick={e => setOpen(!open)}>
          <LogoWhite className="px-4 h-10"></LogoWhite>
        </button>
      </div>
      <a.div style={{ opacity: opacity.interpolate(o => 1 - o), height }}>
        {open && <M setOpen={setOpen} state={open} />}
      </a.div>
    </>
  )
}

const Nav: React.FC<any> = ({ bind }) => {
  const isMobile = useMediaQuery({ minWidth: 768 })
  const [navTransparent, setNavTransparent] = useState(false)

  // useEffect(() => {
  //   if (isBrowser) {
  //     window.location.pathname === "/about/" ? setNavTransparent(true) : setNavTransparent(false)
  //   }
  // }, [])

  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "darpa_logo.png" }) {
        childImageSharp {
          # fluid(maxWidth: 700) {
          #   ...GatsbyImageSharpFluid
          # }
          gatsbyImageData
        }
      }
    }
  `)
  // {`banner ${active ? "active" : ""}`}
  return (
    <div {...bind}>
      {!isMobile ? (
        <MobileNav />
      ) : (
        <div className={`flex flex-row justify-between ${navTransparent ? "bg-black" : "bg-off"}`}>
          {navTransparent ? <LogoWhite className="m-4 h-8  self-center" /> : <Logo className="m-4 h-8  self-center" />}
          <nav className=" font-variable flex flex-row tracking-wide justify-end text-bold items-center md:self-center w-full text-center md:w-auto">

            {routes.map(link => (
              <>
                <StyledLink
                  className={`inline-block my-4 outline-none mx-6 md:flex ${navTransparent ? "text-white" : "text-black"} font-medium text-sm justify-between tracking-wider hover:tracking-wider self-center md:inline-block`}
                  key={link.title}
                  to={link.route}
                  activeStyle={{
                    textDecoration: "underline",
                  }}
                >
                  {link.title}
                </StyledLink>
              </>
            ))}
          </nav>
        </div>
      )}
    </div>
  )
}


function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden"
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle)
  }, [])
}



export default Nav
