exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-releases-tsx": () => import("./../../../src/pages/releases.tsx" /* webpackChunkName: "component---src-pages-releases-tsx" */),
  "component---src-templates-artist-page-tsx": () => import("./../../../src/templates/artistPage.tsx" /* webpackChunkName: "component---src-templates-artist-page-tsx" */),
  "component---src-templates-release-page-tsx": () => import("./../../../src/templates/releasePage.tsx" /* webpackChunkName: "component---src-templates-release-page-tsx" */)
}

